@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);
  $divider-color: mat.m2-get-color-from-palette($foreground, divider);

  .gl-button-toggle {
    @apply tw-inline-flex tw-w-full;
  }

  .mat-button-toggle-group-appearance-standard .gl-button-toggle + .gl-button-toggle {
    // material theme divider color
    border-left: solid 1px $divider-color;
  }

  .mat-button-toggle-button {
    @apply tw-h-full;
  }

  [data-theme='modernize-light'] gl-button-toggle-group,
  [data-theme='modernize-dark'] gl-button-toggle-group {
    // Kiosk default
    .mat-button-toggle-group {
      border-radius: 14px;

      @apply tw-h-11 tw-min-w-64 tw-border-2 tw-border-strong;
    }

    .mat-button-toggle {
      border-radius: 0;
      border: 0;

      @apply oc-text-body tw-w-full tw-bg-surface-default tw-text-secondary;

      .mat-pseudo-checkbox {
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--text-primary);
      }
    }

    .mat-button-toggle-group .gl-button-toggle + .gl-button-toggle {
      border-left: 2px solid rgb(var(--border-strong));
    }

    // Disabled
    .mat-button-toggle-group[aria-disabled='true'] {
      @apply tw-border-default;
    }

    .mat-button-toggle-disabled {
      @apply tw-text-disabled;
    }

    .mat-button-toggle-group[aria-disabled='true'] .gl-button-toggle + .gl-button-toggle {
      border-left-color: rgb(var(--border-default));
    } // Option selected
    .mat-button-toggle-checked {
      @apply tw-bg-primary tw-text-on-primary;
    }
  }
}
