@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $background: mat.m2-get-color-from-palette(gl.$surface, 700);

  .gl-side-nav.mat-sidenav {
    @apply tw-max-w-96 sm:tw-min-w-76;

    gl-sidebar-med-card {
      .mat-mdc-form-field-wrapper {
        @apply tw-pb-0;
      }

      gl-input {
        @apply tw-w-full tw-pb-0;
      }

      gl-select {
        .mat-mdc-form-field {
          @apply sm:tw-w-full;
        }
      }

      gl-counter {
        .mat-mdc-form-field {
          @apply tw-w-full;
        }
      }
    }
  }

  [data-theme='desktop-dark'] .gl-side-nav.mat-sidenav {
    background: $background;

    .gl-sidebar-actions {
      @apply tw-flex tw-justify-end tw-gap-4 tw-pb-4 tw-pr-4;
    }
  }

  [data-theme='modernize-light'] .mat-drawer-backdrop.mat-drawer-shown,
  [data-theme='modernize-dark'] .mat-drawer-backdrop.mat-drawer-shown {
    --mat-sidenav-scrim-color: rgb(0 0 0 / 70%);
  }

  [data-theme='modernize-light'],
  [data-theme='modernize-dark'],
  [data-theme='desktop-dark'] {
    .mat-drawer-container {
      @apply tw-bg-page;
    }
  }

  [data-theme='modernize-light'] .gl-side-nav.mat-sidenav,
  [data-theme='modernize-dark'] .gl-side-nav.mat-sidenav {
    @apply tw-bg-surface-default;

    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;

    .gl-sidebar-actions {
      @apply tw-absolute tw-bottom-0 tw-z-10 tw-flex tw-w-full tw-justify-between tw-border-t tw-border-t-default
        tw-bg-surface-popover tw-px-4 tw-py-3;

      border-bottom-left-radius: 24px;
      box-shadow: 0 4px 16px 0 rgb(0 0 0 / 30%);
    }
  }

  .gl-sidebar-block {
    .mat-mdc-form-field {
      .mat-mdc-form-field-subscript-wrapper {
        @apply tw-hidden;
      }
    }
  }
}
