@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $is-dark-theme: map.get($theme, is-dark);

  .mat-mdc-chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    @apply tw-bg-neutral-100 tw-text-neutral-contrast-100;
  }

  .mat-mdc-chip:hover {
    opacity: 0.95;
  }

  .mdc-evolution-chip__action--primary gl-icon {
    line-height: 1rem;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    @apply oc-text-body tw-bg-neutral-100 tw-text-neutral-contrast-100;

    // tailwind adding border to anything with border-box
    // removing border for all elements within mat-mdc-chip
    *,
    ::before,
    ::after {
      border-style: none;
    }

    // label
    .mdc-evolution-chip__action .mdc-evolution-chip__text-label {
      @apply tw-text-neutral-contrast-100;
    }

    // close button
    .mdc-evolution-chip__cell--trailing {
      .mat-mdc-chip-remove {
        @apply tw-opacity-100;
      }
    }

    // icon in chip
    .mat-mdc-chip-avatar,
    .material-symbols-rounded {
      @apply tw-h-5 tw-w-5 tw-text-neutral-contrast-100;

      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    // colors
    &.mat-info {
      background-color: mat.m2-get-color-from-palette($primary);
    }

    &.mat-success {
      background-color: gl.$success;
    }

    &.mat-warning {
      background-color: gl.$warning;
    }

    &.mat-critical {
      background-color: gl.$critical;
    }
  }

  .gl-chip-small {
    .mat-mdc-chip.mat-mdc-standard-chip {
      height: 1.5rem;

      @apply oc-text-caption;
    }
  }

  .gl-chip-large {
    .mat-mdc-chip.mat-mdc-standard-chip {
      @apply tw-text-lg;

      border-radius: 1.25rem;
      height: 2.5rem;

      // avatar
      .mat-mdc-chip-avatar,
      .material-symbols-rounded {
        @apply tw-h-6 tw-w-6;

        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      // padding on right of close button
      .mdc-evolution-chip__cell--trailing {
        @apply tw-pr-1;
      }
    }
  }

  .gl-chip-outline {
    &.mat-mdc-chip.mat-mdc-standard-chip,
    .mat-mdc-chip.mat-mdc-standard-chip {
      @apply tw-text-neutral-100;

      background-color: if($is-dark-theme, map.get(gl.$surface, 'default'), map.get(gl.$neutral, 'lighter'));
      border: 1px solid;

      // label, icons, close button
      .mdc-evolution-chip__text-label,
      .mat-mdc-chip-avatar,
      .material-symbols-rounded {
        @apply tw-text-neutral-100;
      }

      // colors
      &.mat-info {
        border-color: mat.m2-get-color-from-palette($primary);

        .mdc-evolution-chip__text-label,
        .mat-mdc-chip-avatar,
        .material-symbols-rounded {
          color: mat.m2-get-color-from-palette($primary);
        }
      }

      &.mat-success {
        border-color: gl.$success;

        .mdc-evolution-chip__text-label,
        .mat-mdc-chip-avatar,
        .material-symbols-rounded {
          color: gl.$success;
        }
      }

      &.mat-warning {
        border-color: gl.$warning;

        .mdc-evolution-chip__text-label,
        .mat-mdc-chip-avatar,
        .material-symbols-rounded {
          color: gl.$warning;
        }
      }

      &.mat-critical {
        border-color: gl.$critical;

        .mdc-evolution-chip__text-label,
        .mat-mdc-chip-avatar,
        .material-symbols-rounded {
          color: gl.$critical;
        }
      }
    }
  }
}
