@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  [data-theme='modernize-light'] gl-progress-spinner,
  [data-theme='modernize-dark'] gl-progress-spinner {
    // color of spinner
    .mat-mdc-progress-spinner,
    .mdc-circular-progress__indeterminate-circle-graphic,
    .mdc-circular-progress__determinate-circle-graphic {
      --mdc-circular-progress-active-indicator-color: rgb(var(--decor-secondary));
    }
  }
}
