@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  [data-theme='modernize-light'] gl-state,
  [data-theme='modernize-dark'] gl-state {
    .mat-icon {
      @apply tw-h-auto tw-w-auto;

      font-size: 7.5rem;
    }
  }
}
