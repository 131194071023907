@use '../utilities/' as util;

@mixin theme($theme) {
  [data-theme='desktop-dark'] .mat-divider {
    @include util.color-border;
  }

  [data-theme='modernize-light'] .mat-divider,
  [data-theme='modernize-dark'] .mat-divider {
    @apply tw-border-default;
  }

  gl-divider-grid,
  gl-divider-menu {
    @apply tw-col-span-full tw-w-full;
  }
}
