@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  .cdk-overlay-dark-backdrop {
    background-color: rgb(0 0 0 / 60%);
  }

  .cdk-overlay-pane {
    /* Position caret on top */
    &.gl-top .gl-popover-caret {
      @apply tw-border-t-surface-300;

      border-width: 10px 10px 0;
      bottom: -10px;
      transform: translateX(-50%);
    }

    /* Position caret on bottom */
    &.gl-bottom .gl-popover-caret {
      @apply tw-border-b-surface-300;

      border-width: 0 10px 10px;
      top: -10px;
    }

    &.gl-left .gl-popover-caret {
      left: 10%;
    }

    &.gl-right .gl-popover-caret {
      left: 90%;
    }

    &.gl-center {
      left: 50%;
    }
  }

  // Has to live globally because the cdk overlay is built outside the component DOM
  .gl-patient-banner-overlay {
    @apply tw-flex tw-items-center tw-bg-surface-popover tw-p-md;

    box-shadow: 0 6px 24px 0 rgb(0 0 0 / 10%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .gl-allergies {
      .gl-label,
      .gl-value,
      .mat-icon {
        @apply tw-text-critical;
      }
    }

    .gl-expansion-icon {
      mat-icon.gl-icon-default {
        @apply tw-text-primary;
      }
    }

    .gl-patient-divider {
      @apply tw-mb-md tw-border-b tw-border-solid tw-border-default;
    }
  }

  .gl-patient-banner-backdrop {
    background: rgb(0 0 0 / 25%);
  }

  @screen xl {
    .gl-patient-banner-overlay {
      @apply tw-flex tw-items-center tw-rounded-[36px] tw-border-2 tw-border-solid tw-border-default tw-bg-surface-popover tw-px-lg tw-pb-lg tw-pt-sm;

      .gl-patient-divider {
        @apply tw-mb-0 tw-border-none;
      }
    }

    .gl-patient-banner-backdrop {
      background: none;
    }
  }
}
