@use '@angular/material' as mat;
@use '../core/theming' as gl;

@mixin theme($theme) {
  [data-theme='desktop-dark'] {
    ::-webkit-scrollbar {
      width: 0.75rem;
    }

    ::-webkit-scrollbar-track {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 400);
    }

    ::-webkit-scrollbar-thumb {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 100);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: mat.m2-get-color-from-palette(gl.$surface, 50);
    }
  }

  [data-theme='modernize-light'],
  [data-theme='modernize-dark'] {
    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}
