@use '../utilities/' as util;

@mixin theme($theme) {
  [data-theme] .mat-mdc-card {
    @include util.elevation-flat;
  }

  .gl-card-container {
    grid-auto-rows: 1fr;
  }

  .gl-card {
    @apply tw-h-full;

    .mat-mdc-card-header {
      @apply tw-px-6 tw-py-4 tw-text-primary;
    }

    .mat-mdc-card-subtitle {
      @apply oc-text-body tw-text-secondary;

      --mat-card-subtitle-text-color: rgb(var(--text-secondary));
    }

    .mat-mdc-card-content,
    .mat-mdc-card-content:last-child {
      @apply tw-p-6 tw-pb-6; // padding bottom needed for mdc override
    }
  }

  [data-theme='modernize-light'] gl-card,
  [data-theme='modernize-dark'] gl-card {
    .mat-mdc-card {
      @apply tw-rounded-3xl tw-bg-surface-flat;
    }

    .gl-border {
      @apply tw-border-2 tw-border-default;
    }

    .gl-card {
      @apply tw-overflow-hidden tw-rounded-3xl;
      @include util.elevation-raised;

      .gl-header {
        @apply tw-border-b-2 tw-border-default;
      }

      .mat-mdc-card-header {
        @apply tw-bg-surface-inset;

        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
      }

      .mat-mdc-card-content {
        @apply tw-bg-surface-flat tw-p-6;
      }
      /* stylelint-disable length-zero-no-unit */
      &.gl-interaction-border {
        @apply tw-border-4 tw-border-solid tw-border-transparent;

        background:
          linear-gradient(rgb(var(--neutral-33)), rgb(var(--neutral-33))) padding-box,
          linear-gradient(
              97.55deg,
              rgb(var(--green-400)) -5.63%,
              rgb(var(--green-400)) 13.54%,
              rgb(var(--yellow-100)) 57.37%,
              rgb(var(--blue-300)) 84.39%
            )
            border-box;
        box-shadow: 0px 2px 20px 0px rgb(0 184 72 / 40%);
      }
      /* stylelint-enable length-zero-no-unit */
    }
  }
}
