@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  // up the height since we have a larger font size
  $gl-button-height: var(--spacing-11);

  .mat-mdc-button-touch-target {
    height: inherit !important;
  }

  gl-icon-button,
  gl-icon-button .mdc-icon-button {
    @apply tw-inline-flex tw-h-fit tw-items-center tw-justify-center;
  }

  gl-button {
    @apply tw-inline-block tw-h-fit;

    .mat-mdc-button-base {
      @apply oc-text-body-strong;

      .mdc-button__label {
        @apply tw-inline-flex tw-gap-1;
      }
    }
  }

  [data-theme='desktop-dark'] gl-button {
    .mat-mdc-button-base {
      @apply tw-px-4;
    }
  }

  a {
    &.oc-disabled {
      @apply tw-pointer-events-none tw-opacity-50;
    }
  }

  [data-theme='modernize-light'] gl-button,
  [data-theme='modernize-dark'] gl-button {
    .mat-mdc-button-base.mat-mdc-button-base {
      --mdc-outlined-button-outline-width: 2px;

      @apply tw-rounded-full tw-px-5;

      // disabled button
      --mdc-filled-button-disabled-container-color: rgb(var(--ui-disabled));
      --mdc-filled-button-disabled-label-text-color: rgb(var(--text-on-primary));
      --mdc-outlined-button-disabled-label-text-color: rgb(var(--text-disabled));
      --mdc-outlined-button-disabled-outline-color: rgb(var(--ui-disabled));
      --mdc-text-button-disabled-label-text-color: rgb(var(--text-disabled));
    }

    // filled button
    .mat-mdc-unelevated-button:not(:disabled) {
      @apply tw-bg-primary;

      .mdc-button__label {
        @apply tw-text-on-primary;
      }
    }

    // outlined button
    .mat-mdc-outlined-button.mat-primary:not(.mat-mdc-outlined-button:disabled) {
      --mat-mdc-button-persistent-ripple-color: rgb(var(--blue-600));

      @apply tw-border-2 tw-border-focus;
    }

    // text button
    .mat-mdc-button-base:not(:disabled) {
      --mat-mdc-button-persistent-ripple-color: rgb(var(--blue-600));

      @apply tw-text-link;
    }
  }

  gl-chrome .mdc-button__label {
    @apply tw-text-primary;
  }

  // set the outline of the stroked button to match the text color
  .mat-mdc-outlined-button.mat-primary:not(.mat-mdc-outlined-button:disabled) {
    border-color: mat.m2-get-color-from-palette($primary);
  }

  .mat-mdc-outlined-button.mat-accent:not(.mat-mdc-outlined-button:disabled) {
    border-color: mat.m2-get-color-from-palette($accent);
  }

  .mat-mdc-outlined-button.mat-critical:not(.mat-mdc-outlined-button:disabled) {
    border-color: gl.$critical;
  }

  .mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    @apply tw-whitespace-nowrap;

    letter-spacing: 0.04rem;
  }

  // in the material source this includes .mat-icon-button, we exclude it because we change the default font size
  // and it throws it off center
  .mat-mdc-button-base {
    .mat-icon {
      @apply tw-align-middle;
    }

    &.mdc-button {
      height: $gl-button-height;
    }
  }

  // Icon button in Kiosk
  [data-theme='modernize-light'] .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base,
  [data-theme='modernize-dark'] .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
    @apply tw-h-11 tw-w-11;

    .mat-mdc-button-touch-target {
      @apply tw-h-11 tw-w-11;
    }
  }

  [data-theme='desktop-dark'] gl-icon-button {
    .gl-icon-button-disabled .mat-icon {
      @apply tw-text-disabled;
    }

    .gl-icon-button-success .mat-icon {
      @apply tw-text-success;
    }

    .gl-icon-button-critical .mat-icon {
      @apply tw-text-critical;
    }

    .gl-icon-button-warning .mat-icon {
      @apply tw-text-warning;
    }

    .gl-icon-button-info .mat-icon {
      @apply tw-text-azure;
    }
  }

  [data-theme='modernize-light'] gl-icon-button,
  [data-theme='modernize-dark'] gl-icon-button {
    .gl-icon-button-success {
      --mat-icon-button-ripple-color: rgb(var(--ui-secondary) / 12%);
      --mat-mdc-button-persistent-ripple-color: rgb(var(--ui-secondary) / 12%);
      --mat-icon-button-state-layer-color: rgb(var(--ui-secondary) / 12%);

      .mat-icon {
        @apply tw-text-success;
      }
    }

    .gl-icon-button-critical {
      --mat-icon-button-ripple-color: rgb(var(--ui-critical) / 12%);
      --mat-mdc-button-persistent-ripple-color: rgb(var(--ui-critical) / 12%);
      --mat-icon-button-state-layer-color: rgb(var(--ui-critical) / 12%);

      .mat-icon {
        @apply tw-text-critical;
      }
    }

    .gl-icon-button-warning {
      --mat-icon-button-ripple-color: rgb(var(--ui-warning) / 12%);
      --mat-mdc-button-persistent-ripple-color: rgb(var(--ui-warning) / 12%);
      --mat-icon-button-state-layer-color: rgb(var(--ui-warning) / 12%);

      .mat-icon {
        @apply tw-text-warning;
      }
    }

    .gl-icon-button-info {
      --mat-icon-button-ripple-color: rgb(var(--ui-primary) / 12%);
      --mat-mdc-button-persistent-ripple-color: rgb(var(--ui-primary) / 12%);
      --mat-icon-button-state-layer-color: rgb(var(--ui-primary) / 12%);

      .mat-icon {
        @apply tw-text-brand-primary;
      }
    }

    .gl-icon-button-default {
      --mat-icon-button-ripple-color: rgb(var(--green-900) / 12%);
      --mat-mdc-button-persistent-ripple-color: rgb(var(--green-900) / 12%);
      --mat-icon-button-state-layer-color: rgb(var(--green-900) / 12%);

      .mat-icon {
        @apply tw-text-primary;
      }
    }
  }

  .oc-button-group {
    @apply tw-space-x-4;
  }

  .gl-button-group {
    @apply tw-space-x-4;

    gl-menu[action],
    gl-menu[icon],
    gl-icon-button {
      &,
      + gl-button {
        @apply tw-m-0 #{!important};
      }
    }

    gl-icon-button + gl-menu:not([action], [icon]) {
      @apply tw-m-0 #{!important};
    }
  }

  [data-theme='modernize-light'] .gl-button-group,
  [data-theme='modernize-dark'] .gl-button-group {
    @apply tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-x-4 sm:tw-space-y-0;
  }
}
