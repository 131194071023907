// Icons remaining global as size and color are overwritten in many components
// encapsulation causes unnecessary additional selectors for specificty
@mixin theme($theme) {
  gl-icon {
    display: inline-flex;

    .mat-icon {
      // set icon size in relation to body text
      display: inline-block;
      width: var(--body-line-height);
      height: var(--body-line-height);
      font-size: var(--body-line-height);
    }
  }

  .gl-icon-default {
    @apply tw-text-decor-neutral;
  }

  .gl-icon-success {
    @apply tw-text-decor-success;
  }

  .gl-icon-warning {
    @apply tw-text-decor-warning;
  }

  .gl-icon-critical {
    @apply tw-text-decor-critical;
  }

  .gl-icon-info {
    @apply tw-text-decor-primary;
  }

  .gl-icon-warning-strong {
    @apply tw-text-warning-strong;
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    .gl-icon-default {
      @apply tw-text-decor-neutral;
    }

    .gl-icon-success {
      @apply tw-text-decor-success;
    }

    .gl-icon-warning {
      @apply tw-text-decor-warning;
    }

    .gl-icon-critical {
      @apply tw-text-decor-critical;
    }

    .gl-icon-info {
      @apply tw-text-decor-primary;
    }

    .gl-icon-warning-strong {
      @apply tw-text-warning-strong;
    }
  }
}
