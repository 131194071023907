@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  gl-drawer {
    .gl-drawer {
      @apply oc-text-body tw-flex tw-flex tw-h-4 tw-h-full tw-w-60 tw-items-center tw-justify-center tw-border-4 tw-px-2;

      gl-icon {
        @apply tw-flex tw-h-4;

        .mat-icon {
          @apply tw-h-4 tw-w-4 tw-text-base/4;
        }
      }

      &:hover {
        @apply tw-cursor-pointer tw-border-4;

        border-color: mat.m2-get-color-from-palette(gl.$azure, 500);
      }
    }

    .gl-inactive-drawer {
      @apply tw-opacity-60;
    }

    .gl-active-drawer {
      @apply tw-border-azure-500 tw-opacity-100;
    }

    .gl-deep,
    .gl-double,
    .gl-single,
    .gl-csd,
    .gl-console-screen {
      @apply tw-hidden;
    }

    hr {
      @apply tw-h-1 tw-w-16 tw-border-0;

      background-color: mat.m2-get-color-from-palette(gl.$neutral, 200);
    }

    div.gl-open-shelf {
      @apply tw-h-[50px];
    }

    div.gl-double-deep {
      @apply tw-h-[50px];

      .gl-deep,
      .gl-double,
      .gl-single {
        @apply tw-flex;
      }
    }

    div.gl-double-deep .gl-drawer {
      background-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
    }

    div.gl-single-height {
      @apply tw-h-[25px];

      .gl-deep,
      .gl-single {
        @apply tw-flex;
      }
    }

    div.gl-single-height .gl-drawer {
      background-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
    }

    div.gl-controlled-substance-dispenser {
      @apply tw-h-[125px];

      .gl-csd {
        @apply tw-flex;
      }
    }

    div.gl-controlled-substance-dispenser .gl-drawer {
      background-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
    }

    div.gl-console {
      @apply tw-flex tw-h-[98px];

      background-color: mat.m2-get-color-from-palette(gl.$surface, 800);

      .gl-console-screen {
        @apply tw-block tw-h-20 tw-w-28 tw-justify-self-start tw-border-2;

        background-color: mat.m2-get-color-from-palette(gl.$surface, 500);
        border-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
      }

      &:hover {
        @apply tw-cursor-auto;

        border-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
      }
    }

    div.gl-empty-slot {
      @apply tw-h-[25px];

      .gl-drawer {
        @apply tw-border-[1px];

        border-color: mat.m2-get-color-from-palette(gl.$surface, 100);
      }
    }
  }

  gl-zone {
    .gl-condensed-zone-label {
      @apply tw-text-base/4;
    }

    .gl-zone-icon {
      gl-icon {
        @apply tw-flex tw-h-4;

        .mat-icon {
          @apply tw-h-4 tw-w-4 tw-text-base/4;
        }
      }
    }

    .gl-full-view {
      @apply tw-h-[256px];
    }

    .gl-border-z {
      @apply tw-cursor-pointer tw-border tw-border-solid tw-border-neutral-contrast-50 tw-bg-neutral-100 tw-text-azure-contrast-A100;
    }

    .gl-active-zone {
      @apply tw-border-[3px] tw-border-dv-cat1;

      background-color: mat.m2-get-color-from-palette(gl.$scarlet, A100);
    }
  }

  gl-flexlock {
    @apply tw-cursor-pointer tw-self-end;

    .gl-flexlock {
      @apply tw-relative tw-my-4 tw-flex tw-h-[418px] tw-w-[240px] tw-items-center tw-justify-center tw-border-4 tw-border-solid tw-px-2;

      background-color: mat.m2-get-color-from-palette(gl.$surface, 200);

      .gl-lock {
        @apply tw-absolute tw-left-[-18px] tw-top-[167px] tw-flex tw-h-[64px] tw-w-[54px] tw-items-center tw-justify-center tw-border-2 tw-border-solid;

        border-color: mat.m2-get-color-from-palette(gl.$surface, 400);
        background-color: mat.m2-get-color-from-palette(gl.$surface, '100-contrast');

        .gl-dot {
          @apply tw-absolute tw-bottom-[5px] tw-left-[2px];
        }

        .gl-lock-divider {
          @apply tw-absolute tw-left-[14px] tw-h-[60px] tw-w-[2px];

          background-color: mat.m2-get-color-from-palette(gl.$surface, 400);
        }
      }

      hr {
        @apply tw-absolute tw-bottom-[42px] tw-left-0 tw-h-[4px] tw-w-[232px] tw-border-0;
      }
    }

    .gl-flexlock-zone {
      @apply tw-relative tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-border tw-border-solid tw-border-neutral-contrast-50 tw-bg-neutral-100 tw-text-azure-contrast-A100;
    }

    .gl-active-flexlock {
      @apply tw-border-[3px] tw-border-azure-500 tw-bg-azure-A100 tw-text-azure-500;
    }
  }

  .gl-hscroll-container gl-zone {
    @apply tw-me-16;
  }

  gl-bin-item {
    .gl-bin-text {
      @apply tw-flex tw-flex-col tw-overflow-hidden tw-break-words;

      flex: 1;
      position: relative;
    }

    .gl-bin-text.gl-gradient::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(transparent 71%, mat.m2-get-color-from-palette(gl.$azure, A100));
    }
  }
}
