@mixin theme($theme) {
  [data-theme='desktop-dark'] gl-inline-alert {
    .gl-inline-container {
      @apply tw-rounded tw-bg-surface-300;
    }
  }

  [data-theme='modernize-light'] gl-inline-alert,
  [data-theme='modernize-dark'] gl-inline-alert {
    .gl-inline-container {
      @apply tw-rounded-2xl;
    }

    .gl-bg-critical {
      @apply tw-bg-critical;
    }

    .gl-bg-warning {
      @apply tw-bg-warning;
    }

    .gl-bg-success {
      @apply tw-bg-success;
    }

    .gl-bg-info {
      @apply tw-bg-info;
    }
  }
}
