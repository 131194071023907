@use '@angular/material' as mat;
@use '../core/theming' as gl;
@use '../utilities' as util;
@use 'sass:map';

@mixin theme($theme) {
  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    // To allow sticky headers to work in tabs
    .mat-mdc-tab-body-wrapper,
    .mat-mdc-tab-body.mat-mdc-tab-body-active,
    .mat-mdc-tab-body-content {
      @apply tw-overflow-visible;
    }

    .mdc-tab,
    .mat-mdc-tab-link {
      opacity: 0.8;
      padding: 0 var(--spacing-4);

      &:hover {
        background: rgb(255 255 255 / 10%);
      }

      &:hover,
      &.mdc-tab--active {
        opacity: 1;
      }

      &.mdc-tab--active {
        font-weight: 500;
      }
    }

    .mdc-tab__text-label {
      @apply oc-text-body-strong;
    }

    .mdc-tab-indicator__content--underline {
      border-bottom-width: 2px;
    }

    &.gl-vertical-tabs {
      @apply tw-flex tw-flex-grow tw-flex-row;

      .mat-mdc-tab-header {
        @apply tw-border-0;
      }

      .mat-mdc-tab-labels {
        @apply tw-flex-col;
      }

      .mdc-tab {
        @apply tw-justify-start;

        background-color: mat.m2-get-color-from-palette(gl.$surface, 400);
        margin-top: var(--spacing-1);
      }

      .mat-mdc-tab-body,
      .mat-mdc-tab-body-wrapper {
        @apply tw-flex tw-flex-grow tw-flex-col;
      }

      .mdc-tab-indicator__content--underline {
        @apply tw-hidden;
      }

      .mdc-tab--active::after {
        @include util.status-border;

        background-color: mat.m2-get-color-from-palette(gl.$seafoam);
        inset: unset;
        left: 0;
      }
    }
  }

  [data-theme='modernize-light'] gl-tab-group,
  [data-theme='modernize-dark'] gl-tab-group {
    .mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {
      &.gl-horizontal-tabs {
        .mat-mdc-tab-header {
          @apply tw-items-center tw-justify-between tw-rounded-full tw-border-2 tw-px-1 tw-py-1;

          border-color: rgb(var(--ui-secondary));
        }

        .mdc-tab,
        .mat-mdc-tab-link {
          --mat-tab-header-inactive-ripple-color: rgb(var(--neutral-33));
          --mat-tab-header-inactive-hover-label-text-color: rgb(var(--text-secondary));

          @apply tw-flex-grow tw-items-center tw-px-4 tw-py-1 tw-opacity-100;

          // hide underline
          .mdc-tab-indicator__content--underline {
            @apply tw-hidden;
          }

          .mat-mdc-tab-ripple {
            @apply tw-rounded-full;
          }

          // tab label
          .mdc-tab__text-label {
            --mat-tab-header-inactive-label-text-color: rgb(var(--text-secondary));
          }

          // active tab
          &.mdc-tab--active {
            @apply tw-rounded-full tw-bg-ui-secondary;

            --mat-tab-header-active-hover-label-text-color: rgb(var(--text-on-neutral-strong));
            --mat-tab-header-active-label-text-color: rgb(var(--text-on-neutral-strong));
            --mat-tab-header-active-focus-label-text-color: rgb(var(--text-on-neutral-strong));
            --mat-tab-header-active-focus-indicator-color: rgb(var(--ui-secondary));

            // override active tab with white tag
            gl-tag .gl-tag.gl-tag-filled {
              background-color: rgb(var(--neutral-33));

              @apply tw-text-on-neutral;
            }
          }

          // unselected tabs should have neutral-strong styling
          gl-tag .gl-tag.gl-tag-filled {
            @apply tw-bg-neutral-strong tw-text-on-neutral-strong;
          }
        }
      }

      &.gl-vertical-tabs {
        .mat-mdc-tab,
        .mat-mdc-tab-link,
        .mdc-tab {
          @apply oc-text-body-strong tw-flex tw-min-h-12 tw-min-w-52 tw-items-center tw-bg-surface-flat tw-px-4 tw-py-3 tw-opacity-100;

          .mdc-tab__text-label {
            --mat-tab-header-inactive-label-text-color: rgb(var(--text-secondary));
            --mat-tab-header-active-label-text-color: rgb(var(--text-secondary));
            --mat-tab-header-active-focus-label-text-color: rgb(var(--text-secondary));
            --mat-tab-header-inactive-hover-label-text-color: rgb(var(--text-secondary));
            --mat-tab-header-active-hover-label-text-color: rgb(var(--text-secondary));
          }

          &.mdc-tab--active {
            background-color: rgb(var(--bg-surface-hover));
          }

          // hover ripple
          .mdc-tab__ripple {
            @apply tw-hidden;
          }

          // pressed state ripple
          .mat-ripple-element {
            --mat-tab-header-inactive-ripple-color: rgb(var(--bg-surface-hover));
            --mat-tab-header-active-ripple-color: rgb(var(--bg-surface-hover));
          }
        }

        .mat-mdc-tab:focus .mdc-tab__text-label {
          --mat-tab-header-inactive-focus-label-text-color: rgb(var(--text-secondary));
        }

        .mdc-tab--active::after {
          width: var(--spacing-1);
          border-bottom-right-radius: var(--spacing-2);
          border-top-right-radius: var(--spacing-2);
        }
      }
    }
  }
}
