@mixin theme($theme) {
  [data-theme='modernize-light'] gl-label-value,
  [data-theme='modernize-dark'] gl-label-value {
    .gl-label {
      @apply oc-text-body-strong tw-text-primary;

      &::after {
        content: ':';

        @apply tw-mr-1;
      }
    }

    .gl-value {
      @apply oc-text-body tw-text-primary;
    }

    .gl-link-value {
      @apply oc-text-body tw-text-link;
    }

    // defining smaller size when size is caption in kiosk
    .gl-label-value-caption {
      .gl-label {
        @apply oc-text-caption-strong;
      }

      .gl-value {
        @apply oc-text-caption;
      }
    }
  }
}
