@use '@angular/material' as mat;
@use '@omnicell/greenlight-theme' as gl;
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import '@fontsource/roboto/900.css';
@import '@fontsource-variable/material-symbols-rounded/full.css';
@import '@omnicell/greenlight-theme/tailwind';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(gl.$typography);`
@include mat.all-component-typographies(gl.$typography);
@include mat.core;
@include mat.all-component-themes(gl.$dark-theme);
@include gl.theme-material(gl.$dark-theme);
@include gl.theme-mdc(gl.$dark-theme);
@include gl.theme(gl.$dark-theme);
@include gl.prebuilt(gl.$dark-theme);

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded Variable', sans-serif;
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size in OCP */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
