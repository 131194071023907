@use '../utilities/' as util;

@mixin theme($theme) {
  .gl-stat-widget {
    @include util.elevation-raised;
    @apply tw-h-full;

    .mat-mdc-card-header {
      @apply tw-p-3 tw-pb-10 tw-text-primary;
    }

    .mat-mdc-card-subtitle {
      @apply tw-text-base tw-text-secondary;
    }

    .mat-mdc-card-content {
      @apply tw-overflow-y-auto tw-p-3 tw-pt-0;
    }
  }

  .gl-stat-widget-overlay {
    .mat-mdc-card-header {
      @apply tw-p-3 tw-text-primary;
    }
  }

  .cdk-overlay-container {
    .gl-stat-widget-border-success {
      @apply tw-border-success;
    }

    .gl-stat-widget-border-warning {
      @apply tw-border-warning;
    }

    .gl-stat-widget-border-critical {
      @apply tw-border-critical;
    }
  }

  [data-mode='desktop-mode'] {
    .gl-stat-widget-overlay {
      @apply tw-rounded-2xl tw-border-2;
    }

    gl-stat-widget {
      .mat-mdc-card {
        @apply tw-w-fit tw-min-w-xs tw-max-w-xs tw-rounded-2xl tw-border-2;
      }

      .gl-stat-widget-border-hover-success {
        @apply hover:tw-border-success;
      }

      .gl-stat-widget-border-hover-warning {
        @apply hover:tw-border-warning;
      }

      .gl-stat-widget-border-hover-critical {
        @apply hover:tw-border-critical;
      }
    }

    .gl-stat-widget-icon-bg {
      @apply tw-rounded-lg;
    }
  }

  [data-mode='touch-mode'] {
    .gl-stat-widget,
    .gl-stat-widget-overlay {
      @apply tw-overflow-hidden tw-rounded-3xl;
    }

    .gl-stat-widget-overlay {
      @apply tw-border-4;
    }

    .gl-stat-widget-icon-bg {
      @apply tw-rounded-xl;
    }

    gl-stat-widget {
      .mat-mdc-card {
        @apply tw-w-fit tw-min-w-52 tw-max-w-lg;
      }
    }
  }
}
